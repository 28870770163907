.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 70%;
  max-width: 800px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 10px 5px;
}

.option-modal-title {
  font-size: 20px;
  margin: 20px;
}

.option-section {
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  border-radius: 5px;
}

.option-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

.option-input {
  width: auto;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.option-select {
  width: auto;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.option-save-button {
  margin: 10px;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  background-color: #4caf50;
  cursor: pointer;
}
