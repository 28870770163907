.footer {
  height: auto;
  background-color: #333333;
  min-width: 1000px;
  color: #cfcfcf;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.footer-link {
  text-decoration: underline;
  color: inherit;
}

.footer-link:hover {
  text-decoration: none;
}
