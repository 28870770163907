html,
body,
.App,
#root {
  height: 100%;
}

*,
*::before,
*::after {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

body {
  background-color: #282c34;
  font-family: 'Arial', sans-serif;
  user-select: none;
  -webkit-user-select: none;
}

.App {
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.container {
  background-color: #282c34;
  margin-top: 10px;
  height: 92vh;
  min-height: 600px;
  min-width: 1000px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1.5vmin);
  color: white;
  padding: 0 5%;
  overflow: auto;
}

button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: calc(8px + 1.5vmin);
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

button span {
  display: block;
  margin-top: 5px;
}
