.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 70%;
  max-width: 800px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 10px 5px;
}

.modal-content {
  padding: 20px;
  max-height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.champion-item {
  flex-basis: calc(10% - 10px);
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}

.champion-item img {
  width: 50px;
  height: 50px;
  border-radius: 0%;
  clip-path: inset(2px);
  margin-right: 0px;
}

.champion-item span {
  font-size: 12px;
  margin-top: 5px;
}

.banned {
  filter: grayscale(100%);
  color: #a9a9a9;
}

.champion-item.banned {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.clear-button,
.close-button {
  margin-top: 10px;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.close-button {
  background-color: #ff4d4d;
}

.close-button:hover {
  background-color: #ff1a1a;
}
